<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>Übersicht</v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-card-title>Kommende Veranstaltungen</v-card-title>
              <v-data-table :headers="header" :items="eventsUpcoming" disable-pagination disable-filtering disable-sort>
                  <template v-slot:[`item.startDate`]="{item}">
                      {{ item.startDate | convertISODateToDE }}
                  </template>
                <template v-slot:[`item.action`]>
                  <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-col>

          <v-col>
            <v-card elevation="0">
              <v-card-title>Neue Veranstaltungen</v-card-title>
              <v-data-table :headers="header" :items="eventsNew" disable-pagination disable-filtering disable-sort>
                  <template v-slot:[`item.startDate`]="{item}">
                      {{ item.startDate | convertISODateToDE }}
                  </template>
                <template v-slot:[`item.action`]>
                  <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-col>

          <v-col>
            <v-card elevation="0">
              <v-card-title>Interne Veranstaltungen</v-card-title>
              <v-data-table :headers="header" :items="eventsInternal" disable-pagination disable-filtering disable-sort>
                  <template v-slot:[`item.startDate`]="{item}">
                      {{ item.startDate | convertISODateToDE }}
                  </template>
                <template v-slot:[`item.action`]>
                  <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Dashboard",
  computed: {
      eventsNew() {
          return this.preparedEvents.filter(item => item.new === true)
      },
      eventsUpcoming() {
          return this.preparedEvents.filter(item => {
              let date = new Date(item.startDate)
              if (date > new Date()) return item
          })
      },
      eventsInternal() {
          return this.eventsUpcoming.filter(item => item.type === 'internal')
      },
      events() {
          return this.$store.getters['events/getAll']
      },
      places() {
          return this.$store.getters['places/getAll']

      }
  },
    methods: {
      prepareEvents() {
          let events = []
          this.events.forEach(event => {
              const place = this.$store.getters['places/getByIri'](event.place)
              const placeName = place ? place.name : ''
              events.push({
                  'title': event.title,
                  'startDate': event.startDate,
                  'place': placeName
              })
          })

          this.preparedEvents = events
      }
    },
  data() {
    return {
      header: [
        { text: 'Name', value: 'title'},
        { text: 'Startdatum', value: 'startDate'},
        { text: 'Ort', value: 'place'},
        { text: '', value: 'action'},
      ],
        preparedEvents: []
    }
  },
    watch: {
        events: function() {
            this.prepareEvents()
        },
        places: function() {
            this.prepareEvents()
        }
    }
}
</script>

<style scoped>

</style>