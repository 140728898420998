<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="text--primary">
                Stände {{ institution.title }}
                <v-spacer></v-spacer>
                <v-btn @click="openNewDialog">Neuen Stand anlegen</v-btn>
            </v-card-title>
            <v-card elevation="0" class="ma-4">
                <v-layout>
                    <v-row>
                        <v-col cols="3" v-for="booth in booths" :key="booth.id">
                            <BoothCard :booth-id="booth.id"></BoothCard>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-card>
            <v-card-actions>
                <v-btn :to="{name: 'institution'}">Zurück</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showDialog" max-width="800">
            <v-card>
                <v-img min-height="400px" :src="item.image">
                    <template v-slot:placeholder>
                        <v-sheet
                            color="grey lighten-3"
                            elevation="2"
                            min-height="100%"
                            class="d-flex justify-center align-center"
                        >
                            <v-btn outlined @click="$refs.fileUpload.click()">Foto hochladen</v-btn>
                        </v-sheet>
                    </template>
                </v-img>
                <v-card-title v-if="item.id === 0">Neuen Stand anlegen</v-card-title>
                <v-card-title v-else>Stand bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Name" v-model="item.title"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-file-input
                                    label="Foto des Standes" ref="fileUpload" size="1000000" hint="Maximal 1 Mb"
                                    accept="image/*" @change="uploadImage" @click:clear="item.image = ''"
                                    :show-size="true"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row v-if="errorFileUpload">
                            <v-col>
                                <v-alert type="error">Bitte nur Dateien bis 1 MB hoch laden.</v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field type="number" label="Länge [m]" v-model="item.length"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field type="number" label="Breite [m]" v-model="item.width"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field type="number" label="Höhe [m]" v-model="item.height"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch label="Wird Strom benötigt?" v-model="item.needEnergy"></v-switch>
                            </v-col>
                            <v-col>

                            </v-col>
                            <v-col>
                                <v-switch label="Wird Wasser benötigt?" v-model="item.needWater"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select v-if="item.needEnergy" label="Welcher Anschluss wird benötigt?" v-model="item.energyType" :items="energyTypes" item-text="label" item-value="value"></v-select>
                            </v-col>
                            <v-col>

                            </v-col>
                            <v-col>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Kommentar" v-model="item.comment"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="showDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addItem" v-if="item.id === 0">Anlegen</v-btn>
                    <v-btn color="success" @click="updateItem" v-else>Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import BoothCard from "@/views/booth/BoothCard.vue";

export default {
    // eslint-disable-next-line
    name: "Institution",
    components: {BoothCard},
    props: ['institutionId'],
    computed: {
        booths() {
            return this.$store.getters['booths/getByInstitution'](this.institutionId)
        },
        institution() {
            return this.$store.getters['institutions/getById'](this.institutionId)
        }
    },
    data() {
        return {
            errorFileUpload: false,
            energyTypes: [
                {value: 'schuko', label: 'Schuko 16A'},
                {value: 'cee16', label: 'CEE 16A'},
                {value: 'cee32', label: 'CEE 32A'},
                {value: 'cee64', label: 'CEE 64A'}
            ],
            showDialog: false,
            item: {
                id: 0,
                title: '',
                width: 0,
                length: 0,
                height: 0,
                needEnergy: false,
                needWater: false,
                institution: 0,
                comment: '',
                image: null
            },
            template: {
                id: 0,
                title: '',
                width: 0,
                length: 0,
                height: 0,
                needEnergy: false,
                needWater: false,
                institution: 0,
                comment: '',
                image: null
            }
        }
    },
    methods: {
        openNewDialog() {
            this.item = Object.assign({}, this.template)
            this.showDialog = true
        },
        openEditDialog(id) {
            this.item = Object.assign({}, this.$store.getters['booths/getById'](id))
            this.showDialog = true
        },
        addItem() {
            const vue = this
            delete this.item.id
            this.item.width = parseFloat(this.item.width)
            this.item.length = parseFloat(this.item.length)
            this.item.height = parseFloat(this.item.height)
            this.item.institution = '/institutions/' + this.institution.id
            this.$store.dispatch('booths/add', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        updateItem() {
            const vue = this
            this.item.width = parseFloat(this.item.width)
            this.item.length = parseFloat(this.item.length)
            this.item.height = parseFloat(this.item.height)
            this.$store.dispatch('booths/update', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        uploadImage(file) {
            if (file) {
                const vue = this
                const reader = new FileReader()

                reader.onload = (e) => {
                    if (e.total < 1000000) {
                        vue.item.image = e.target.result
                        this.errorFileUpload = false
                    } else {
                        this.errorFileUpload = true
                    }
                }
                reader.readAsDataURL(file)
            } else {
                console.log('error')
            }
        }
    }
}
</script>

<style scoped>

</style>