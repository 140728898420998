<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="text--primary">Institutionen</v-card-title>
            <v-card elevation="0">
                <v-card-title>
                    <v-text-field label="Suche" v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn @click="openNewDialog">Neue Institution anlegen</v-btn>
                </v-card-title>
                <v-data-table :headers="header" :items="institutions" :search="search">
                    <template v-slot:[`item.street`]="{ item }">
                        {{ item.street }} {{ item.streetNumber }}
                    </template>
                    <template v-slot:[`item.city`]="{ item }">
                        {{ item.postal }} {{ item.city }}
                    </template>
                    <template v-slot:[`item.phone`]="{ item }">
                        <a :href="'tel:' + item.phone">{{ item.phone }}</a>
                    </template>
                    <template v-slot:[`item.email`]="{ item }">
                        <a :href="'mailto:' + item.email">{{ item.email }}</a>
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                        <v-btn icon @click="openEditDialog(item.id)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon :to="{name: 'userList', params: {institutionId: item.id}}">
                            <v-icon>mdi-account</v-icon>
                        </v-btn>
                        <v-btn icon :to="{name: 'boothList', params: {institutionId: item.id}}">
                            <v-icon>mdi-castle</v-icon>
                        </v-btn>
                        <v-btn fab x-small :color="item.isBlacklisted ? 'red' : 'green'"></v-btn>
                        <!-- <v-btn icon><v-icon color="success">mdi-eye</v-icon></v-btn>
                         <v-btn icon><v-icon color="error">mdi-delete</v-icon></v-btn>-->
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
        <v-dialog v-model="showDialog" max-width="1000">
            <v-card>
                <v-card-title v-if="institutionItem.id === 0">Neue Institution anlegen</v-card-title>
                <v-card-title v-else>Institution bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Titel" v-model="institutionItem.title"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-switch label="Sperrliste" v-model="institutionItem.isBlacklisted" hint="Die Institution ist bei den letzten Veranstaltungen negativ aufgefallen"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Straße" v-model="institutionItem.street"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Hausnummer" v-model="institutionItem.streetNumber"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Postleitzahl" v-model="institutionItem.postal"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Ort" v-model="institutionItem.city"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field type="email" label="E-Mail" v-model="institutionItem.email"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field type="url" label="Homepage" v-model="institutionItem.homepage"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field type="phone" label="Telefon" v-model="institutionItem.phone"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="showDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addNewPlace" v-if="institutionItem.id === 0">Anlegen</v-btn>
                    <v-btn color="success" @click="savePlace" v-else>Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: "Institution",
    computed: {
        institutions() {
            return this.$store.getters['institutions/getAll']
        }
    },
    data() {
        return {
            header: [
                {text: 'Name', value: 'title'},
                {text: 'Straße', value: 'street'},
                {text: 'Ort', value: 'city'},
                {text: 'E-Mail', value: 'email'},
                {text: 'Telefon', value: 'phone'},
                {text: '', value: 'action'},
            ],
            search: '',
            showDialog: false,
            institutionItem: {
                id: 0,
                title: '',
                street: '',
                streetNumber: '',
                postal: '',
                city: '',
                email: '',
                homepage: '',
                phone: ''
            },
            institutionTemplate: {
                id: 0,
                title: '',
                street: '',
                streetNumber: '',
                postal: '',
                city: '',
                email: '',
                homepage: '',
                phone: ''
            }
        }
    },
    methods: {
        openNewDialog() {
            this.institutionItem = Object.assign({}, this.institutionTemplate)
            this.showDialog = true
        },
        openEditDialog(id) {
            this.institutionItem = Object.assign({}, this.$store.getters['institutions/getById'](id))
            delete this.institutionItem.users
            delete this.institutionItem.events
            this.showDialog = true
        },
        addNewPlace() {
            const vue = this
            delete this.institutionItem.id
            this.$store.dispatch('institutions/add', this.institutionItem).then(function () {
                vue.showDialog = false
                vue.institutionItem = Object.assign({}, vue.institutionTemplate)
            })
        },
        savePlace() {
            const vue = this
            this.$store.dispatch('institutions/update', this.institutionItem).then(function () {
                vue.showDialog = false
                vue.institutionItem = Object.assign({}, vue.institutionTemplate)
            })
        }
    }
}
</script>

<style scoped>

</style>