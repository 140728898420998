<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="text--primary">
                Benutzer {{ institution.title }}
                <v-spacer></v-spacer>
                <v-btn @click="openNewDialog">Neuen Benutzer anlegen</v-btn>
            </v-card-title>
            <v-card elevation="0" class="ma-4">
                <v-layout>
                    <v-row>
                        <v-col cols="3" v-for="item in user" :key="item.id">
                            <v-card>
                                <v-card-title>{{ item.firstname }} {{ item.lastname }}</v-card-title>
                                <v-card-text>
                                    <a :href="'tel:' + item.phone">{{ item.phone }}</a><br>
                                    <a :href="'mailto:' + item.email">{{ item.email }}</a>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn icon @click="openEditDialog(item.id)">
                                        <v-icon>mdi mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon :disabled="true">
                                        <v-icon>mdi mdi-account-key</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="deleteItem(item.id)" v-if="currentUser.id !== item.id">
                                        <v-icon color="error">mdi-delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-card>
            <v-card-actions>
                <v-btn :to="{name: 'institution'}">Zurück</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showDialog" max-width="1000">
            <AccountEditCard :account-id="item.id" :institution-iri="'/institutions/' + this.institution.id" v-on:cancel="showDialog = false"></AccountEditCard>
        </v-dialog>
    </v-container>
</template>

<script>
import AccountEditCard from "@/views/account/AccountEditCard.vue";

export default {
    // eslint-disable-next-line
    name: "Institution",
    components: {AccountEditCard},
    props: ['institutionId'],
    computed: {
        user() {
            return this.$store.getters['user/getByInstitution'](this.institutionId)
        },
        institution() {
            return this.$store.getters['institutions/getById'](this.institutionId)
        },
        currentUser () {
            return this.$store.getters['auth/currentUser']
        }
    },
    data() {
        return {
            showDialog: false,
            item: {
                id: 0,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                institution: 0,
                plainPassword: ''
            },
            template: {
                id: 0,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                institution: 0,
                plainPassword: ''
            }
        }
    },
    methods: {
        openNewDialog() {
            this.item = Object.assign({}, this.template)
            this.showDialog = true
        },
        openEditDialog(id) {
            this.item = Object.assign({}, this.$store.getters['user/getById'](id))
            this.showDialog = true
        },
        addItem() {
            const vue = this
            delete this.item.id
            this.item.institution = '/institutions/' + this.institution.id
            this.item.roles = ['ROLE_USER']
            this.$store.dispatch('user/add', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        updateItem() {
            const vue = this
            this.$store.dispatch('user/update', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        deleteItem(id) {
            if (confirm('Soll der Benutzer wirklich gelöscht werden?')) {
                this.$store.dispatch('user/delete', id)
            }
        }
    }
}
</script>

<style scoped>

</style>