import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter)

import Event from "@/views/Event";
import Institution from "@/views/Institution";
import Dashboard from "@/views/Dashboard";
import Accounts from "@/views/Accounts";
import Place from "@/views/Place.vue";
import Booths from "@/views/Booths.vue";
import EventBooths from "@/views/EventBooths.vue";


const routes = [
    { path: '/', name: 'dashboard', component: Dashboard },
    { path: '/institution', name: 'institution', component: Institution },
    { path: '/institution/:institutionId/user', name: 'userList', component: Accounts, props: true },
    { path: '/institution/:institutionId/booth', name: 'boothList', component: Booths, props: true },
    { path: '/place', name: 'place', component: Place },
    { path: '/event', name: 'event', component: Event },
    { path: '/event/:eventId/booth', name: 'eventBoothList', component: EventBooths, props: true },
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router;
