import Vue from 'vue'
import Vuex from 'vuex'
import events from './modules/events'
import places from './modules/places'
import institutions from './modules/institutions'
import user from './modules/user'
import auth from './modules/auth'
import booths from "@/store/modules/booths";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        events,
        places,
        institutions,
        user,
        booths
    },
})
