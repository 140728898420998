<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title>Veranstaltungen</v-card-title>
            <v-card elevation="0">
                <v-card-title>
                    <v-checkbox label="Vergangene Veranstaltungen ausblenden" v-model="hideOldEvents"></v-checkbox>
                    <v-spacer/>
                    <v-spacer/>
                    <v-select v-model="showYear" label="Jahr" :items="availableYears" :clearable="true"></v-select>
                    <v-spacer/>
                    <v-spacer/>
                    <v-btn @click="openNewDialog">Neue Veranstaltung anlegen</v-btn>
                </v-card-title>
                <v-data-table :headers="header" :items="events">
                    <template v-slot:[`item.startDate`]="{item}">
                        {{ item.startDate | convertISODateToDE }}
                    </template>
                    <template v-slot:[`item.endDate`]="{item}">
                        {{ item.endDate | convertISODateToDE }}
                    </template>
                    <template v-slot:[`item.type`]="{ item }">
                        <div v-if="item.type === 'internal'">Intern</div>
                        <div v-else-if="item.type === 'public'">Öffentlich</div>
                        <div v-else-if="item.type === 'approval'">Genehmigungspflichtig</div>
                    </template>
                    <template v-slot:[`item.place`]="{ item }">
                        {{ getPlace(item.place).name }}
                    </template>
                    <template v-slot:[`item.institution`]="{ item }">
                        {{ getInstitution(item.institution).title }}
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                        <v-btn icon>
                            <v-icon @click="openEditDialog(item.id)">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon :color="item.hasOwnProperty('published') ? 'success' : ''">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon v-if="item.type === 'internal'" :to="{name: 'eventBoothList', params: {eventId: item.id}}">
                            <v-icon>mdi-castle</v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
        <v-dialog v-model="showDialog" max-width="1000">
            <v-card>
                <v-card-title v-if="item.id === 0">Neue Veranstaltung anlegen</v-card-title>
                <v-card-title v-else>Veranstaltung bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Name" v-model="item.title"></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-select label="Typ" v-model="item.type" :items="type"
                                          item-text="label" item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select label="Institution" v-model="item.institution" :items="institutions"
                                          item-text="label" item-value="value"></v-select>
                            </v-col>
                            <v-col>
                                <v-select label="Ort" v-model="item.place" :items="places"
                                          item-text="label" item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2>Startdatum</h2>
                                <v-date-picker v-model="item.startDate" first-day-of-week="1" flat locale="de"></v-date-picker>
                            </v-col>
                            <v-col>
                                <h2>Enddatum</h2>
                                <v-date-picker v-model="item.endDate" first-day-of-week="1" flat locale="de"></v-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch label="Gastronomisch" v-model="item.isGastronomical"></v-switch>
                            </v-col>
                            <v-col>
                                <v-switch label="Wird Eintritt genommen?" v-model="item.isChargeable"></v-switch>
                            </v-col>
                            <v-col>
                                <v-switch label="Wird Musik gespielt?" v-model="item.hasMusic"></v-switch>
                            </v-col>
                            <v-col>
                                <v-switch label="Sollen Straßen/Plätze gesperrt werden?"
                                          v-model="item.needStreetClosure"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch label="Wird Strom benötigt?" v-model="item.needEnergy"></v-switch>
                            </v-col>
                            <v-col>
                                <v-switch label="Wird Wasser/Abwasser benötigt?" v-model="item.needWater"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Bemerkungen / Angebotene Waren / Ausstellungsgegenstände" v-model="item.comment"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="showDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addItem" v-if="item.id === 0">Anlegen</v-btn>
                    <v-btn color="success" @click="updateItem" v-else>Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: "Event",
    computed: {
        events() {
            let events = this.$store.getters['events/getAll']
            if (this.hideOldEvents) {
                events =  events.filter(item => {
                    let date = new Date(item.startDate)
                    if (date > new Date()) return item
                })
            }

            if (this.showYear !== null) {
                events =  events.filter(item => {
                    let date = item.startDate.split('-')
                    if (date[0] === this.showYear) return item
                })
            }
            return events
        },
        availableYears() {
            return this.$store.getters['events/getAll'].reduce((items, item) => {
                const year = item.startDate.split('T')[0].split('-')[0]
                if (!items.includes(year)) {
                    items.push(year)
                }
                return items
            }, [])
        },
        institutions() {
            let institutions = []
            this.$store.getters['institutions/getAll'].forEach(item => {
                institutions.push({
                    'label': item.title,
                    'value': '/institutions/' + item.id
                })
            })

            return institutions
        },
        places() {
            let places = []
            this.$store.getters['places/getAll'].forEach(item => {
                places.push({
                    'label': item.name,
                    'value': '/places/' + item.id
                })
            })

            return places
        }
    },
    methods: {
        getInstitution(iri) {
            return this.$store.getters['institutions/getByIri'](iri)
        },
        getPlace(iri) {
            return this.$store.getters['places/getByIri'](iri)
        },
        openNewDialog() {
            this.item = Object.assign({}, this.template)
            this.showDialog = true
        },
        openEditDialog(id) {
            this.item = Object.assign({}, this.$store.getters['events/getById'](id))
            const startDate = this.item.startDate.split('T')
            this.item.startDate = startDate[0]
            const endDate = this.item.endDate.split('T')
            this.item.endDate = endDate[0]
            this.showDialog = true
        },
        addItem() {
            const vue = this
            delete this.item.id
            this.$store.dispatch('events/add', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        updateItem() {
            const vue = this
            this.$store.dispatch('events/update', this.item).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        }
    },
    data() {
        return {
            hideOldEvents: true,
            showYear: null,
            header: [
                {text: 'Name', value: 'title'},
                {text: 'Startdatum', value: 'startDate'},
                {text: 'Enddatum', value: 'endDate'},
                {text: 'Ort', value: 'place'},
                {text: 'Institution', value: 'institution'},
                {text: 'Typ', value: 'type'},
                {text: '', value: 'action'},
            ],
            type: [
                { label: 'Öffentlich', value: 'public' },
                { label: 'Intern', value: 'internal' },
                { label: 'Genehmigungspflichtig', value: 'approval' }
            ],
            showDialog: false,
            item: {
                id: 0,
                title: '',
                startDate: '',
                endDate: '',
                type: '',
                isChargeable: false,
                isGastronomical: false,
                hasMusic: false,
                needStreetClosure: false,
                needEnergy: false,
                needFreshwater: false,
                needSewage: false,
                comment: '',
                institution: '',
                place: ''
            },
            template: {
                id: 0,
                title: '',
                startDate: '',
                endDate: '',
                type: '',
                isChargeable: false,
                isGastronomical: false,
                hasMusic: false,
                needStreetClosure: false,
                needEnergy: false,
                needFreshwater: false,
                needSewage: false,
                comment: '',
                institution: '',
                place: ''
            }
        }
    }
}
</script>

<style scoped>

</style>