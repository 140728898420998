<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="text--primary">
                Stände für {{ event.title }}
                <v-spacer></v-spacer>
                <v-btn @click="openNewDialog">Neuen Stand hinzufügen</v-btn>
            </v-card-title>
            <v-card elevation="0" class="ma-4">
                <v-layout>
                    <v-row>
                        <v-col cols="3" v-for="booth in eventBooths" :key="booth.id">
                            <BoothCard :booth-id="booth.id"></BoothCard>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-card>
            <v-card-actions>
                <v-btn :to="{name: 'event'}">Zurück</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showDialog" max-width="1000">
            <v-card>
                <v-card-title v-if="item.id === 0">Neuen Stand anlegen</v-card-title>
                <v-card-title v-else>Stand bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-select label="Institution" v-model="item.institution" :items="institutions"
                                          item-text="label" item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" v-for="booth in availableBooths" :key="booth.id">
                                <v-card>
                                    <v-card-title>{{ booth.title }}</v-card-title>
                                    <v-card-text>
                                        Größe: {{ booth.length }} x {{ booth.width }} x {{ booth.height }} m<br>
                                        Wasser: <v-icon color="success" v-if="booth.needWater">mdi-water</v-icon><v-icon color="error" v-else>mdi-water-off</v-icon><br>
                                        Strom: <v-icon color="success" v-if="booth.needEnergy">mdi-flash</v-icon><v-icon color="error" v-else>mdi-flash-off</v-icon><br>
                                        {{ booth.comment }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="success" @click="addBoothToEvent(booth.id)">Hinzufügen</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="showDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import BoothCard from "@/views/booth/BoothCard.vue";

export default {
    // eslint-disable-next-line
    name: "EventBooths",
    components: {BoothCard},
    props: ['eventId'],
    computed: {
        event() {
            return this.$store.getters['events/getById'](this.eventId)
        },
        eventBooths() {
            return this.$store.getters['booths/getByEvent'](this.eventId)
        },
        institutions() {
            let institutions = []
            this.$store.getters['institutions/getAll'].forEach(item => {
                institutions.push({
                    'label': item.title,
                    'value': '/institutions/' + item.id
                })
            })

            return institutions
        },
        availableBooths() {
            let booths = []
            this.$store.getters['booths/getByInstitutionIri'](this.item.institution).forEach(item => {
                if (!this.eventBooths.includes(item))
                booths.push(item)
            })

            return booths
        }
    },
    data() {
        return {
            showDialog: false,
            item: {
                institution: '',
                booth: ''
            },
            template: {
                institution: '',
                booth: ''
            }
        }
    },
    methods: {
        openNewDialog() {
            this.item = Object.assign({}, this.template)
            this.showDialog = true
        },
        addBoothToEvent(boothId) {
            const vue = this
            let event = Object.assign({}, this.event)
            event.booths.push('/booths/' + boothId)
            this.$store.dispatch('events/update', event).then(function () {
                vue.showDialog = false
                vue.item = Object.assign({}, vue.template)
            })
        },
        getInstitutionName(institutionIri) {
            return this.$store.getters['institutions/getByIri'](institutionIri).title
        }
    }
}
</script>

<style scoped>

</style>