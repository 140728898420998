import ApiService from "@/services/api.service";
import Vue from "vue";

const state = () => ({
    all: []
})

// actions
const actions = {
    loadAll(context) {
        ApiService.get("/institutions").then(response => {
            context.commit("SET", response.data);
        });
    },
    add(context, item) {
        return new Promise((resolve, reject) => {
            ApiService.post("/institutions", item)
                .then(response => {
                    context.commit("ADD", response.data);
                    context.dispatch("loadAll");
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    update(context, item) {
        return new Promise((resolve, reject) => {
            ApiService.put("/institutions/" + item.id, item)
                .then(response => {
                    context.commit("UPDATE", response.data);
                    context.dispatch("loadAll");
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

// mutations
const mutations = {
    ADD(state, item) {
        state.all.push(item);
    },
    SET(state, items) {
        state.all = items;
    },
    UPDATE(state, item) {
        const index = state.all[item.id];
        Vue.set(state.all, index, item);
    },
    DEL(state, item) {
        state.all.splice(state.all[item.id], 1);
    }
};

const getters = {
    getAll: (state) => {
        return state.all
    },
    getById: state => id => {
        return state.all.find(item => item.id === id)
    },
    getByIri: (state) => (iri) => {
        return state.all.find(item => '/institutions/' + item.id === iri)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}