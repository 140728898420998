import JwtService from "../../services/jwt.service";
import ApiService from "../../services/api.service";
import router from "@/plugins/router";

const state = {
  isAuthenticated: false,
  user: null,
  roles: [],
  errors: {}
};

// getters
const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getRoles(state) {
    return state.roles;
  },
  checkRole: state => role => {
    if (state.roles === undefined) return false;
    if (typeof state.roles === "object")
      return Object.values(state.roles).indexOf(role) !== -1;
    return state.roles.indexOf(role) !== -1;
  }
};

// actions
const actions = {
  async login(context, credentials) {
    try {
      context.commit("PURGE_AUTH");
      ApiService.clearHeader();
      const response = await ApiService.post("authentication_token", {
        email: credentials.email,
        password: credentials.password
      });
      JwtService.saveToken(response.data.token);
      const data = JwtService.getData();
      ApiService.setHeader();
      context.commit("SET_AUTH", data);
      if (router.history.current.path !== "/")
        router.push({ name: "dashboard" });
      return response;
    } catch (error) {
      context.commit("SET_ERROR", error);
      context.commit("PURGE_AUTH");
      return Promise.reject(error);
    }
  },
  logout(context) {
    JwtService.destroyToken();
    ApiService.clearHeader();
    context.commit("PURGE_AUTH");
  },
  checkAuth(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      context.commit("PURGE_AUTH");
    }
  }
};

// mutations
const mutations = {
  SET_ERROR(state, error) {
    state.errors = error;
  },
  SET_AUTH(state, data) {
    state.isAuthenticated = true;
    state.user = {
      id: data.user.id,
      firstname: data.user.firstname,
      lastname: data.user.lastname
    };
    state.roles = data.roles;
    state.errors = {};
  },
  PURGE_AUTH(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.roles = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
