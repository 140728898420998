<template>
    <v-chip>{{ label }}</v-chip>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: "EnergyType",
    props: ['energyType'],
    computed: {
        label () {
            return this.energyTypes.find(item => item.value === this.energyType).label
        }
    },
    data() {
        return {
            energyTypes: [
                {value: 'schuko', label: 'Schuko 16A'},
                {value: 'cee16', label: 'CEE 16A'},
                {value: 'cee32', label: 'CEE 32A'},
                {value: 'cee64', label: 'CEE 64A'}
            ]
        }
    }
}
</script>

<style scoped>

</style>