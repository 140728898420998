<template>
    <v-card>
        <v-card-title v-if="accountId > 0">Benutzer bearbeiten</v-card-title>
        <v-card-title v-else>Neuen Benutzer anlegen</v-card-title>
        <v-form ref="accountForm">
            <v-container>
                <v-row>
                    <v-col>
                        <v-text-field label="Vorname" v-model="account.firstname"
                                      :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Nachname" v-model="account.lastname"
                                      :rules="[rules.required]"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field type="email" label="E-Mail" v-model="account.email"
                                      :rules="[rules.required, rules.email, isEmailAvailable]"
                                      :disabled="accountId > 0"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field type="phone" label="Telefon" v-model="account.phone"
                                      :rules="[rules.required]"></v-text-field>
                    </v-col>
                </v-row>
                <v-row  v-if="accountId === 0">
                    <v-col>
                        <v-text-field label="Passwort" v-model="account.plainPassword" type="password"
                                      :rules="[rules.required, rules.validPassword]"
                                      counter></v-text-field>
                    </v-col>
                    <v-col></v-col>
                </v-row>
                <v-row v-if="accountId > 0 && isOwnAccount">
                    <v-col cols="12">
                        <h3>Passwort ändern</h3>
                    </v-col>
                    <v-col>
                        <v-text-field label="Neues Passwort"
                                      v-model="newPassword"
                                      type="password"
                                      :rules="[rules.validPassword]"
                                      counter></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Neues Passwort (Wiederholung)"
                                      v-model="newPasswordRepeated"
                                      type="password"
                                      :rules="[rules.validPassword, validateNewPassword]"
                                      counter></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-card-actions>
            <v-btn @click="saveAccount()" color="success">Speichern</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="cancel()" color="warning">Abbrechen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'AccountEditCard',
    props: ['accountId', 'institutionIri'],
    data: () => ({
        rules: {
            required: v => !!v || "Dies ist ein Pflichtfeld",
            email: v => /.+@.+\..+/.test(v) || 'Keine gültige E-Mailadresse',
            validPassword: v => {
                if (v !== undefined && (v === '' || v.length >= 8)) {
                    return true
                }
                return 'Bitte mindesten 8 Zeichen eingeben'
            },

        },
        header: [
            []
        ],
        account: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            institution: ''
        },
        accountTemplate: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            institution: ''
        },
        newPassword: '',
        newPasswordRepeated: ''
    }),
    computed: {
        isOwnAccount() {
            return this.accountId === this.currentUser.id;
        },
        currentUser () {
            return this.$store.getters['auth/currentUser']
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel')
        },
        saveAccount() {
            if (this.$refs.accountForm.validate()) {
                if (this.isOwnAccount && this.newPassword !== '') {
                    this.account.plainPassword = this.newPassword
                }
                if (this.accountId === 0) {
                    this.$store.dispatch('user/add', this.account)
                } else {
                    this.$store.dispatch('user/update', this.account)
                }
                this.cancel()
            }
        },
        loadAccount(id) {
            if (this.accountId > 0) {
                this.account = this.$store.getters['user/getById'](id)
            } else {
                this.account = Object.assign({}, this.accountTemplate)
                this.account.institution = this.institutionIri
            }
            this.$refs.accountForm.resetValidation()
        },
        validateNewPassword() {
            if (this.account.newPassword === '' || this.account.newPasswordRepeated === this.account.newPassword) {
                return true
            }
            return 'Stimmt nicht mit dem neuen Passwort überein'
        },
        isEmailAvailable() {
            if (this.accountId > 0) return true;
            return this.$store.getters['user/getByEmail'](this.account.email) === undefined || 'E-Mail ist bereits registriert'
        }
    },
    mounted() {
        this.loadAccount(this.accountId)
    },
    watch: {
        accountId: function () {
            this.loadAccount(this.accountId)
        }
    }
}
</script>