import { render, staticRenderFns } from "./EventBooths.vue?vue&type=template&id=75dc929a&scoped=true&"
import script from "./EventBooths.vue?vue&type=script&lang=js&"
export * from "./EventBooths.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75dc929a",
  null
  
)

export default component.exports