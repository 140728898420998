<template>
    <v-card class="fill-height">
        <v-img :src="booth.image" aspect-ratio="1.5">
            <template v-slot:placeholder>
                <v-sheet
                        color="grey lighten-3"
                        elevation="2"
                        min-height="100%"
                        class="d-flex justify-center align-center"
                >
                </v-sheet>
            </template>
        </v-img>
        <v-card-title>{{ booth.title }}</v-card-title>
        <v-card-subtitle> {{ getInstitutionName(booth.institution) }}</v-card-subtitle>
        <v-card-text>
            Größe: {{ booth.length }} x {{ booth.width }} x {{ booth.height }} m<br>
            Wasser:
            <v-icon color="success" v-if="booth.needWater">mdi-water</v-icon>
            <v-icon color="error" v-else>mdi-water-off</v-icon>
            <br>
            Strom:
            <v-icon color="success" v-if="booth.needEnergy">mdi-flash</v-icon>
            <v-icon color="error" v-else>mdi-flash-off</v-icon>
            <template v-if="booth.needEnergy">
                <EnergyType :energy-type="booth.energyType"></EnergyType>
            </template>
            <br>
            {{ booth.comment }}
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
    </v-card>
</template>

<script>
import EnergyType from "@/views/booth/EnergyType.vue";

export default {
    // eslint-disable-next-line
    name: "BoothCard",
    components: {EnergyType},
    props: ['boothId'],
    computed: {
        booth() {
            return this.$store.getters['booths/getById'](this.boothId)
        }
    },
    methods: {
        getInstitutionName(institutionIri) {
            return this.$store.getters['institutions/getByIri'](institutionIri).title
        }
    }
}
</script>

<style scoped>

</style>