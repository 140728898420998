<template>
    <v-container>
        <v-card elevation="0">
            <v-card-title class="text--primary">Orte</v-card-title>
            <v-card elevation="0">
                <v-card-title>
                    <v-text-field label="Suche" v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn @click="openNewDialog()">Neuen Ort anlegen</v-btn>
                </v-card-title>
                <v-data-table :headers="header" :items="places" :search="search">
                    <template v-slot:[`item.street`]="{ item }">
                        {{ item.street }} {{ item.streetNumber }}
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                        <v-btn icon @click="openEditDialog(item.id)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <!--<v-btn icon>
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>-->
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
        <v-dialog v-model="showDialog" max-width="1000">
            <v-card>
                <v-card-title v-if="placeItem.id === 0">Neuen Ort anlegen</v-card-title>
                <v-card-title v-else>Ort bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Name" v-model="placeItem.name"></v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Straße" v-model="placeItem.street"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Hausnummer" v-model="placeItem.streetNumber"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Postleitzahl" v-model="placeItem.postal"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Ort" v-model="placeItem.city"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field type="number" label="Latitude" v-model="placeItem.lat"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field type="number" label="Longitude" v-model="placeItem.lng"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Homepage" v-model="placeItem.homepage" type="url"></v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="showDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addNewPlace" v-if="placeItem.id === 0">Anlegen</v-btn>
                    <v-btn color="success" @click="savePlace" v-else>Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: "Place",
    computed: {
        places() {
            return this.$store.getters['places/getAll']
        },
        institutions() {
            return this.$store.getters['institutions/getAll']
        },

    },
    data() {
        return {
            header: [
                {text: 'Name', value: 'name'},
                {text: 'Straße', value: 'street'},
                {text: 'Ort', value: 'city'},
                {text: 'Latitude', value: 'lat'},
                {text: 'Longitude', value: 'lng'},
                {text: '', value: 'action'},
            ],
            types: [{
                key: 'approval_needed',
                label: 'Genehmigung erforderlich'
            }, {
                key: 'internal',
                label: 'Intern',
            }, {
                key: 'public',
                label: 'Öffentlich'
            }],
            search: '',
            showDialog: false,
            placeItem: {
                id: 0,
                name: '',
                street: '',
                streetNumber: '',
                postal: '',
                city: '',
                lat: 0.0,
                lng: 0.0,
                homepage: null
            },
            placeTemplate: {
                id: 0,
                name: '',
                street: '',
                streetNumber: '',
                postal: '',
                city: '',
                lat: 0.0,
                lng: 0.0,
                homepage: null
            }
        }
    },
    methods: {
        openNewDialog() {
            this.placeItem = Object.assign({}, this.placeTemplate)
            this.showDialog = true
        },
        openEditDialog(id) {
            this.placeItem = Object.assign({}, this.$store.getters['places/getById'](id))
            this.showDialog = true
        },
        addNewPlace() {
            const vue = this
            this.placeItem.lat = parseFloat(this.placeItem.lat)
            this.placeItem.lng = parseFloat(this.placeItem.lng)
            delete this.placeItem.id
            this.$store.dispatch('places/add', this.placeItem).then(function () {
                vue.showDialog = false
                vue.placeItem = Object.assign({}, vue.placeTemplate)
            })
        },
        savePlace() {
            const vue = this
            this.placeItem.lat = parseFloat(this.placeItem.lat)
            this.placeItem.lng = parseFloat(this.placeItem.lng)
            this.$store.dispatch('places/update', this.placeItem).then(function () {
                vue.showDialog = false
                vue.placeItem = Object.assign({}, vue.placeTemplate)
            })
        }
    }
}
</script>

<style scoped>

</style>