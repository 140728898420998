import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import store from "../store";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    Vue.axios.defaults.headers.common['Accept'] = 'application/json';
    Vue.axios.defaults.headers.put['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.get['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.delete['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
      response => {
        if (response.status === 401) {
          store.dispatch("auth/logout");
          return;
        }
        return response;
      },
      function(error) {
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
          return;
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  clearHeader() {
    if (Vue.axios.defaults.headers.common["Authorization"] !== undefined) {
      delete Vue.axios.defaults.headers.common["Authorization"];
    }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  },

  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  },

  async post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  }
};

export default ApiService;
